import React from "react"
import SanityTexte from "../SanityTexte"

const TexteUI = ({ input }) => {
  const { subheading, title, texte } = input
  return (
    <section className="texte-ui bb- px-md md:px-7  ">
      <div className="inner pt-5 pb-9 bb">
        <div className="row center-xs">
          <div className="col-md-6 col-xs-12">
            <div className="header text-center py-md- mb-md md:mb-0 font-sans">
              <div className="subheading text-sm mb-sm md:mb-0 md:pb-sm">
                {subheading}
              </div>
              <h2 className="title text-lg pb-1e- max-w-[604px] mx-auto">
                {title}
              </h2>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="col-xs-2">
            <h3 className="text-xs text-right font-sans  mb-md md:pb-lg md:mb-1e uppercase">
              ABOUT
            </h3>
          </div>
        </div>

        <div className="row center-xs">
          <div className="col-md-6 col-xs-12">
            <div className="text-center">
              <SanityTexte input={texte} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TexteUI

/*
<section className="texte-ui bb- px-md md:px-xl  ">
      <div className="row center-xs">
        <div className="col-md-6 col-xs-12">
          <div className="header text-center py-md- mb-md md:mb-0 font-sans">
            <div className="subheading text-sm mb-md md:pb-1e">
              {subheading}
            </div>
            <h2 className="title text-lg pb-1e- max-w-[604px] mx-auto">
              {title}
            </h2>
          </div>
          <h3 className="text-xs font-sans md:-translate-x-full text-left md:text-right mb-md md:pb-lg md:mb-1e uppercase">
            contacts
          </h3>
          <div className="text-center">
            <SanityTexte input={texte} />
          </div>
        </div>
      </div>
    </section>
*/
