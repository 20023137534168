import React from "react"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { GatsbyImage } from "gatsby-plugin-image"

// const sanityConfig = { projectId: "s2j9sc95", dataset: "production" };
import sanityConfig from "../../config/sanity"

const SanityImage = ({
  image,
  caption,
  objectFit = "contain",
  // showPlaceholder = true,
}) => {
  const { asset } = image
  const { metadata } = asset

  const width = 2000
  const height = width / metadata.dimensions.aspectRatio
  // const imagePlaceholder = getImage(asset);
  const imageData = getGatsbyImageData(
    asset,
    {
      // width: width,
      // height: height,
      // fit: "FILLMAX",
      placeholder: "BLURRED",
    },
    sanityConfig.sanity
  )
  // console.log(imageData);

  return (
    <figure className="w-full- h-full- pointer-events-none relative">
      {/* {showPlaceholder && (
        <div
          className="absolute bg-placeholder w-full h-full"
          style={{
            backgroundColor: metadata.palette.dominant.background,
          }}
        ></div>
      )} */}
      <GatsbyImage
        image={imageData}
        alt={caption ? caption : ""}
        objectFit={objectFit}
      />
      {caption && (
        <figcaption className="text-tertiar-y- text-sm- italic absolute bottom-0 left-0 p-xs md:p-sm text-secondary mix-blend-difference z-10 ls-0">
          {caption}
        </figcaption>
      )}
    </figure>
  )
}

export default SanityImage
