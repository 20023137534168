import React, { useState, useEffect } from "react"

const SlideSvg = ({ url }) => {
  const [svg, setSvg] = useState(null)
  useEffect(() => {
    const headers = {
      // mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }
    fetch(url, headers)
      .then(res => res.text())
      .then(res => {
        // console.log(res)
        setSvg(res)
      })
      .catch(console.error.bind(console))
  }, [])

  return (
    <div className="slide-svg">
      <div
        className=""
        dangerouslySetInnerHTML={{ __html: svg }}
        style={
          {
            // position: "absolute",
            // width: "0",
            // height: "0",
          }
        }
      />
    </div>
  )
}

export default SlideSvg
