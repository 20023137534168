import React from "react"
import { useSiteMetadata } from "../hooks/useSiteMetadata"

export const Seo = ({ title, description, image, pathname, children }) => {
  const { site, buildTime } = useSiteMetadata()
  const {
    title: defaultTitle,
    description: defaultDescription,
    image: defautlImage,
    siteUrl,
    // twitter,
    author,
  } = site

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || `${siteUrl}${defautlImage}`,
    url: `${siteUrl}${pathname || ``}`,
    author: author,
    // twitter,
  }
  // console.log(seo)

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: seo.url,
    //headline,
    //inLanguage: siteLanguage,
    mainEntityOfPage: seo.url,
    description: seo.description,
    name: seo.title,
    author: {
      "@type": "Person",
      name: seo.author,
    },
    copyrightHolder: {
      "@type": "Person",
      name: seo.author,
    },
    copyrightYear: new Date().getFullYear(),
    creator: {
      "@type": "Person",
      name: seo.author,
    },
    publisher: {
      "@type": "Person",
      name: author,
    },
    dateModified: buildTime,
    image: {
      "@type": "ImageObject",
      url: `${seo.image}`,
    },
  }
  // console.log(schemaOrgWebPage)
  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={seo.url} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      {/* <meta name="twitter:creator" content={seo.twitter} /> */}
      {/* <link
        rel="icon"
        href="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><text y='1.1em' font-size='70' font-family='Helvetica, Arial, sans-serif'>C</text></svg>"
      /> */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgWebPage)}
      </script>
      {children}
    </>
  )
}
