// import clsx from "clsx"
import React from "react"
import styled from "styled-components"
// import SanityImage from "../SanityImage"
// import SanityTexte from "../SanityTexte"
import SlickSlider from "../ui/slick-slider"
import SlideSvg from "./SlideSvg"
import useDeviceDetect from "../../hooks/useDeviceDetect"
import { useState } from "react"
import { useEffect } from "react"
import clsx from "clsx"
import { subscribe, unsubscribe } from "pubsub-js"
// import { useScroll } from "../../hooks/useScroll"

/*
- slide 1 left white, right white
- slide 2 left black, right white
- slide 3 left white, right white
- slide 4 left black, right white
- slide 5 left white, right black
- slide 6 left black, right black
*/
const Section = styled.section`
  aspect-ratio: 1 / 0.56464573;
  @media all and (max-width: 768px) {
    aspect-ratio: 0.7 / 1;
  }
  &.slide-02,
  &.slide-04,
  &.slide-06 {
    .slick-arrow.slick-prev::after {
      border-color: black;
    }
  }
  &.slide-05,
  &.slide-06 {
    .slick-arrow.slick-next::after {
      border-color: black;
    }
  }

  @media all and (max-width: 1024px) {
    &.slide-02,
    /* &.slide-04, */
    &.slide-06 {
      .slick-arrow.slick-prev::after {
        border-color: white;
      }
    }
  }
`

const Slide = styled.div`
  vertical-align: top;
  aspect-ratio: 1 / 0.56464573;

  @media all and (max-width: 768px) {
    aspect-ratio: 0.7 / 1;
  }
  .header {
    max-width: 1220px;
    @media all and (min-width: 768px) {
      margin-top: 0.1em;
    }
  }
  /* figure, */
  .gatsby-image-wrapper-constrained {
    /* min-height: 100vh; */
    &,
    img {
      /* height: 100vh; */
      width: 100vw;
      /* max-height: var(--app-height); */
    }
  }
  figcaption {
    left: unset;
    right: 30px;
    @media all and (max-width: 768px) {
      right: 0;
    }
  }

  @media all and (max-width: 768px) {
    .header {
      .title {
        padding: 0 1em;
      }
    }
  }
`

const Slider = ({ input }) => {
  // console.log(input)
  const { isMobile } = useDeviceDetect()
  const [ready, setReady] = useState()
  const [index, setIndex] = useState()
  // const { isBelowViewPort } = useScroll()

  useEffect(() => {
    setReady(true)

    const token = subscribe("SLIDER_CHANGE", (e, d) => {
      console.log(d)
      setIndex(d)
      // document.body.classList.add(`slide-0${d + 1}`)
      document.body.dataset.slideIndex = d + 1
    })

    return () => unsubscribe(token)
  })
  // console.log(isMobile)
  return (
    <Section
      className={clsx(
        "relative slider-ui h-screen- mb-lg- md:mb-xl- overflow-hidden transition-opacity duration-300 delay-75- delay-300 ",
        ready ? "opacity-100" : "opacity-0",
        `slide-0${index + 1}`
      )}
    >
      {ready && (
        <SlickSlider>
          {input.slides.map((item, i) => (
            <Slide
              className="slide"
              key={i}
              data-index={i}
              id={item.slug.current}
            >
              <SlideSvg
                url={
                  isMobile ? item.imageMobile.asset.url : item.image.asset.url
                }
              />
            </Slide>
          ))}
        </SlickSlider>
      )}
      <figcaption className="italic absolute bottom-0 right-0 p-xs md:p-sm md:px-md text-secondary text-xs md:text-sm mix-blend-difference z-10 ls-0">
        @ Rodin by Thomas Lohr Studio
      </figcaption>
    </Section>
  )
}

export default Slider

{
  /* {item.image.asset.extension !== "svg" && (
              <>
                <div className="bg w-screen h-screen- grayscale- text-xs md:text-sm">
                  <SanityImage
                    image={item.image}
                    objectFit="cover"
                    caption={item.credit}
                  />
                </div>
                <div
                  className={clsx(
                    "header absolute z-10 left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 w-full md:w-auto text-secondary text-center px-md"
                    // i % 2 ? "mix-blend-difference " : ""
                  )}
                  style={
                    {
                      // color: "red",
                    }
                  }
                >
                  <div className="inner">
                    <div className="subheading text-sm-alt md:text-md font-sans pb-xs md:pb-md  drop-shadow-md absolute w-full text-center -top-1/2">
                      {item.subheading}
                    </div>

                    <div className="title text-_xl md:text-xl md:whitespace-nowrap drop-shadow-md">
                      {item.title}
                    </div>
                    <div className=" text-sm- sm:text-center md:text-left pt-md md:absolute w-full md:w-1/2 drop-shadow-md">
                      {item.texte && <SanityTexte input={item.texte} />}
                    </div>
                  </div>
                </div>
              </>
            )} */
}
