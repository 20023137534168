import React, { useState } from "react"
import clsx from "clsx"
import { publish } from "pubsub-js"
import Burger from "./ui/Burger"
// import styled from "styled-components"
// import MenuItem from "./MenuItem"

// const Burger = styled.div`
//   &::before,
//   &::after {
//     content: "";
//     height: 20px;
//     width: 1px;
//     position: absolute;
//     background-color: white;
//   }
// `

const Menu = ({ input }) => {
  // console.log(input)
  const [active, setActive] = useState(false)
  // console.log(active)
  const _onClick = item => {
    switch (item.type) {
      case "slide":
        _slideTo(item)
        break
      case "scroll":
        _scrollTo(item)
        break
      default:
        break
    }
  }

  const _slideTo = item => {
    const target = document.getElementById(item.anchor)
    const index = target.dataset.index
    // console.log(target, item.anchor)
    window.scrollTo({ top: 0, behavior: "smooth" })
    publish("SLIDER_INDEX", index)
    // setActive(false)
    publish("BURGER.CLOSE")
  }

  const _scrollTo = item => {
    const target = document.getElementById(item.anchor)
    // console.log(target)
    target.scrollIntoView({ behavior: "smooth" })
    // setActive(false)
    publish("BURGER.CLOSE")
  }

  return (
    <>
      <div
        className={clsx(
          "z-10- px-lg fixed z-50 top-lg top-0- w-full- mix-blend-difference-",
          active ? "is-active" : ""
        )}
        onMouseEnter={() => setActive(true)}
        style={{
          paddingTop: "0.1em",
        }}
      >
        <Burger onChange={val => setActive(val)} active={active} />
      </div>
      <nav
        className={clsx(
          "fixed z-40 top-0 md:top-lg w-full md:w-auto h-screen md:h-auto pointer-events-none",
          active ? "is-active " : ""
        )}
        onMouseLeave={() => setActive(false)}
        style={{
          paddingTop: "0.1em",
        }}
      >
        <ul
          className={clsx(
            " p-lg py-[98px] md:py-lg bg-secondary text-md text-primary top-0 z-0 transition-all duration-300 h-full",
            active
              ? "translate-x-0 opacity-100 pointer-events-auto"
              : "-translate-x-full opacity-0- pointer-events-none"
          )}
        >
          {input.map((item, i) => (
            <li key={i}>
              <button
                className={clsx(
                  "menu-item lowercase first-letter:capitalize  ",
                  i < input.length - 1 ? "pb-sm" : ""
                )}
                onClick={() => _onClick(item)}
              >
                {item.title}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </>
  )
}

export default Menu
