import React from "react"
import { graphql } from "gatsby"
import { Seo } from "../components/Seo"
import Modules from "../components/modules"
import Menu from "../components/Menu"

export const query = graphql`
  query PageQ($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      id
      slug {
        current
      }
      seo {
        metaTitle
        metaDescription
        metaImage {
          asset {
            url
          }
        }
      }
      title
      modules {
        ... on SanitySliderUI {
          _key
          _type
          slides {
            inMenu
            slug {
              current
            }
            subheading
            title
            texte: _rawTexte(resolveReferences: { maxDepth: 10 })
            image: _rawImage(resolveReferences: { maxDepth: 10 })
            imageMobile: _rawImageMobile(resolveReferences: { maxDepth: 10 })
            credit
          }
        }
        ... on SanityTexteUI {
          _key
          _type
          inMenu
          slug {
            current
          }
          subheading
          title
          texte: _rawTexte(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityProjectsUI {
          _key
          _type
          inMenu
          slug {
            current
          }
          subheading
          title
          items {
            slug {
              current
            }
            title
            texte: _rawTexte(resolveReferences: { maxDepth: 10 })
            image {
              image: _rawImage(resolveReferences: { maxDepth: 10 })
              credit
            }
            clientWebsite {
              label
              link
            }
            clientInstagram {
              label
              link
            }
            imageMaxSize
          }
        }
      }
    }
  }
`

const Page = ({ data }) => {
  const { modules } = data.sanityPage

  // console.log(modules)
  const _collectAnchors = () => {
    let anchors = []

    const slides = modules.filter(el => el._type === "sliderUI")
    if (slides.length) {
      const withAnchor = slides[0].slides.filter(el => el.inMenu)
      const withAnchorObjects = withAnchor.map(el => {
        return {
          title: el.subheading,
          anchor: el.slug.current,
          type: "slide",
        }
      })
      anchors = anchors.concat(withAnchorObjects)
    }

    const leftover = modules.filter(el => el.inMenu)
    if (leftover) {
      const withAnchorObjects = leftover.map(el => {
        // console.log(el)
        return {
          title: el.title,
          anchor: el.slug.current,
          type: "scroll",
        }
      })
      anchors = anchors.concat(withAnchorObjects)
    }

    const contacts = {
      title: "Contact",
      anchor: "contact",
      type: "scroll",
    }
    anchors = anchors.concat(contacts)

    return anchors
  }
  const menu = _collectAnchors()

  return (
    <div className="page">
      <Menu input={menu} />
      <Modules input={modules} />
    </div>
  )
}
export default Page

// export function Head({ data }) {
export const Head = ({ location, params, data, pageContext }) => {
  const { seo } = data.sanityPage
  console.log(seo)
  return (
    <Seo
      title={seo.metaTitle}
      description={seo.metaDescription}
      image={seo.metaImage.asset.url}
    />
  )
}
