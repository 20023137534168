import React from "react"
import styled from "styled-components"
import SanityImage from "../SanityImage"
import SanityTexte from "../SanityTexte"

const Article = styled.article`
  h3 {
    /* line-height: 1; */
  }
  img {
    /* max-height: 80vh; */
    /* width: auto;
    margin: 0 auto; */
  }
`

const Project = ({ input }) => {
  // console.log(input)
  return (
    <Article
      className="project mb-[60px]- md:mb-[120px]- mb-9"
      id={input.slug.current}
    >
      <div className="mb-sm md:mb-2 md:pb-sm-">
        <div className="row">
          <div className="col-xs-2">
            <div className="text-xs font-sans text-right whitespace-nowrap">
              {input.title}
            </div>
          </div>
        </div>
      </div>
      <div className=" mb-[25px]- md:mb-lg- md:pb-sm- mb-5 flex justify-center text-xs md:text-sm">
        {input.image.image && (
          <div
            style={{
              maxWidth: `${input.imageMaxSize}px`,
            }}
          >
            <SanityImage
              image={input.image.image}
              caption={input.image.credit}
            />
            <div className="footer flex justify-end text-tertiary text-xs md:text-sm pt-xs md:pt-sm italic ls-0 absolute right-0">
              <a
                href={input.clientInstagram.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {input.clientInstagram.label}
              </a>
            </div>
          </div>
        )}
        {/* {!input.image && (
          <img
            src="https://cdn.sanity.io/images/wku76fe2/production/83be6436fe927343bf61d110893d30d1888c7c18-1440x1084.jpg"
            alt=""
            // width={700}
          />
        )} */}
      </div>
      <div className="body text-center">
        <div className="row center-xs">
          <div className="col-md-6">
            <h3 className="text-lg font-sans mb-[13px] md:mb-3_5 md:pb-sm text-crop- lh-1">
              <a
                href={input.clientWebsite.link}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-primary-50"
              >
                {input.title}
              </a>
            </h3>
            <div className="serif">
              <SanityTexte input={input.texte} />
            </div>
          </div>
        </div>
      </div>
    </Article>
  )
}

export default Project
