import React from "react"
import Project from "./Project"

const Projects = ({ input }) => {
  const { slug, subheading, title, items } = input

  return (
    <section className="projects px-md md:px-7 pt-5 md:pt-7" id={slug.current}>
      {/* <div className="hr bb bg-tertiary- my-lg md:mb-xl md:mt-xxl"></div> */}

      <div className="header text-center py-md- font-sans mb-6">
        <h3 className="subheading text-sm mb-sm md:pb-1e-">{subheading}</h3>
        <h2 className="title text-lg pb-1e- max-w-[604px] mx-auto lh-1">
          {title}
        </h2>
      </div>

      {items.map((item, i) => (
        <Project key={i} input={item} />
      ))}
    </section>
  )
}

export default Projects
