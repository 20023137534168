import React from "react"
import TexteUI from "./TexteUI"
import ProjectsUI from "./ProjectsUI"
import SliderUI from "./SliderUI"
import "./index.scss"

const Modules = ({ input }) => {
  const _renderModules = () => {
    const _modules = input.map((module, i) => {
      switch (module._type) {
        case "sliderUI":
          return <SliderUI key={module._key} input={module} />
        case "texteUI":
          return <TexteUI key={module._key} input={module} />
        case "projectsUI":
          return <ProjectsUI key={module._key} input={module} />

        default:
          return null
      }
    })
    return _modules
  }

  return <div className="modules">{_renderModules()}</div>
}

export default Modules
