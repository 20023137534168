import React, { useEffect, useState, useRef } from "react"
import ReactSlick from "react-slick"
// import SliderCursorPrevNextText from "./SliderCursorPrevNextText"
import { subscribe, unsubscribe, publish } from "pubsub-js"
import useDeviceDetect from "../../../hooks/useDeviceDetect"
import "./slick.css" //impossible de l'importer direct ici à cause de purge-css

const SlickSlider = ({ children, settingsOverride }) => {
  const sliderRef = useRef()
  const [index, setIndex] = useState()
  const { isMobile } = useDeviceDetect()

  const _sliderBeforeChange = (oldIndex, newIndex) => {
    // setH2(input[newIndex].project.document.data.title.text);
    setIndex(newIndex)
  }

  useEffect(() => {
    // console.log(sliderRef)
    setTimeout(() => {
      publish("SLIDER_CHANGE", 0)
    }, 150)

    const token = subscribe("SLIDER_INDEX", (e, d) => {
      sliderRef.current.slickGoTo(d)
    })

    document.addEventListener("keyup", _onKeyBoard)

    return () => {
      unsubscribe(token)
      document.removeEventListener("keyup", _onKeyBoard)
    }
  }, [])

  useEffect(() => {
    console.log(index)
    publish("SLIDER_CHANGE", index)
  }, [index])

  const _onKeyBoard = e => {
    // console.log(e)
    switch (e.code) {
      case "ArrowRight":
        sliderRef.current.slickNext()
        return
      case "ArrowLeft":
        sliderRef.current.slickPrev()
        return
      default:
        return
    }
  }

  const settingsDefault = {
    // autoplay: !isMobile,
    // autoplaySpeed: 6000,
    autoplaySpeed: 6000,
    autoplay: false,
    // autoplay: false,
    dots: false,
    speed: isMobile ? 300 : 1500,
    // speed: 10,
    swipeToSlide: true,
    slidesToScroll: 1,
    cssEase: "cubic-bezier(0.53, 0, 0.36, 1)",
    // lazyLoad: "ondemand",
    // variableWidth: true,
    beforeChange: _sliderBeforeChange,
    // prevArrow: <SliderCursorPrevNextText label="prev" className="prev" />,
    // nextArrow: <SliderCursorPrevNextText label="next" className="next" />,
  }
  const settings = {
    ...settingsDefault,
    ...settingsOverride,
  }
  // console.log(settings)

  return (
    <ReactSlick {...settings} ref={sliderRef}>
      {children}
    </ReactSlick>
  )
}

export default SlickSlider
